import React from 'react'
import {notification} from 'antd'
import i18n from 'i18next'

import variables from 'ca-common/variables'

const DEFAULT_DURATION = 4.5
const HEADER_HEIGHT = 60
const MARGIN_FROM_HEADER = 16
const TOP_OFFSET = HEADER_HEIGHT + MARGIN_FROM_HEADER

const copyTextConfig = {
    height: '32px',
    width: '250px',
    padding: '0px',
    background: variables.lightGreenNoOpacity,
    borderColor: variables.systemGreen,
    borderRadius: variables.borderRadiusBase,
    borderStyle: 'solid',
    borderWidth: '1px',
    fontFamily: variables.publicPageFont,
    fontSize: variables.fontSizeSecondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const openSuccessNotification = (
    description?: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:success'),
    duration = DEFAULT_DURATION
): void => {
    notification.success({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openErrorNotification = (
    description: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:error'),
    duration = DEFAULT_DURATION
): void => {
    notification.error({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openInfoNotification = (
    description: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:info'),
    duration = DEFAULT_DURATION
): void => {
    notification.info({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openWarningNotification = (
    description: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:warning'),
    duration = DEFAULT_DURATION
): void => {
    notification.warning({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openCopiedNotification = (
    successText: React.ReactNode,
    iconImg: React.ReactNode,
    closeIcon: React.ReactNode
): void => {
    notification.warning({
        message: successText,
        duration: DEFAULT_DURATION,
        top: TOP_OFFSET,
        placement: 'bottom',
        style: copyTextConfig,
        icon: iconImg,
        closeIcon: closeIcon
    })
}
