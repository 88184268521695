import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'
import {rgba} from 'polished'
import variables from 'ca-common/variables'

export const StyledEntityRow = styled.div<{isHighlighted?: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${variables.gutterXs};
    padding: 0 ${variables.gutterXs};

    ${props => {
        if (props.isHighlighted) {
            return css`
                padding: 2px ${variables.gutterXs};
                border: 1px solid ${variables.orange};
                margin-bottom: 2px;
                background: ${rgba(variables.orange, 0.1)};
                border-radius: 2px;
            `
        }
    }}
`
export const RowName = styled.p`
    font-weight: 400;
    color: ${variables.navyAsphalt};
    margin: 0 12px 0 0;

    @media screen and (min-width: ${variables.screenDesktop}) {
        margin: 0 20px 0 0;
    }
`
export const RowValue = styled.p`
    color: ${variables.navyBlue};
    margin: 0;
`

export const StyledIcon = styled(Icon)<{isWarningIcon?: boolean}>`
    margin-left: 4px;

    path {
        fill: ${variables.orange};
    }
`
