import React, {useEffect} from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {I18nextProvider} from 'react-i18next'
import {BrowserRouter} from 'react-router-dom'

import {i18n} from 'src/locales'

import {GlobalStyles} from 'ca-common/ui-lib/GlobalStyles'
import {inlineManualInit} from 'ca-common/features/InlineManual'

import {PortalRouting} from 'src/newcore/components/Routing'
import {store} from './newAppStore'
import {PORTAL_BASENAME} from 'ca-common/constants'

export const CARoot = () => {
    useEffect(() => {
        inlineManualInit()
    }, [])

    return (
        <I18nextProvider i18n={i18n}>
            <GlobalStyles />
            <Provider store={store}>
                <BrowserRouter basename={`/${PORTAL_BASENAME}`}>
                    <PortalRouting />
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    )
}

export const renderCARoot = (domElementId: string) => {
    render(<CARoot />, document.getElementById(domElementId))
}
