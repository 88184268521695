export const GETTING_STARTED_BACKUP_ACTIVATION_URL =
    'https://support.cloudally.com/hc/en-us/articles/7972915948188-Getting-Started-Backup-Activation'
export const GETTING_STARTED_RESTORE_DATA_URL =
    'https://support.cloudally.com/hc/en-us/articles/7973130216988-Getting-Started-Restore-Your-SaaS-Data'
export const SEE_ALL_URL = 'https://support.cloudally.com/hc/en-us/categories/360001298753'
export const USER_GUIDES_URL =
    'https://support.cloudally.com/hc/en-us/sections/5740301414940-User-Guides-Online-and-PDF'
export const PRODUCT_RELEASES_URL = 'https://support.cloudally.com/hc/en-us/categories/5723000420764'
export const headerGradient =
    'linear-gradient(51deg,#0e122b -6.49%,#0f142f 13.56%,#131939 29.42%,#19224a 43.82%,#222e61 57.29%,#243167 60.09%,#23346a 71.9%,#203e72 81.13%,#1b4f7f 89.49%,#146693 97.33%,#0b84ab 102.22%,#088cb2 106.49%)'
export const END_USER_GUIDES_URL =
    'https://support.cloudally.com/hc/en-us/articles/16320743674396-End-User-Recovery-User-Guide'
