import React from 'react'

import {Integrations} from 'ca-common/features/Integrations'
import {TODO_ANY} from 'ca-common/types'
import {INTEGRATION_TYPE} from 'ca-common/constants'

import {
    useGetIntegrationStatusQuery,
    useAddIntegrationTypeMutation,
    useDeleteIntegrationTypeMutation,
    useEditIntegrationTypeMutation,
    useFetchApiKeyDetailsQuery,
    useGenerateApiKeyMutation,
    useDeleteApiKeyMutation
} from 'src/newcore/features/IntegrationsPortal/lib/redux'

import {getUserInfo} from 'src/newcore/utils/store'
import {isWhiteLable} from 'src/newcore/utils/companyInfo'
import {track} from 'src/newcore/utils/mixpanel'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'

export const IntegrationsPortal = () => {
    const userInfo = getUserInfo()
    const isZix = userInfo?.response?.integrationType === INTEGRATION_TYPE.ZIX
    const isWL = isWhiteLable()
    const portalAllowLink = !(isZix || isWL)
    const accountInfo = userInfo?.response

    return (
        <Integrations
            getIntegrationStatus={useGetIntegrationStatusQuery}
            addIntegration={useAddIntegrationTypeMutation}
            deleteIntegration={useDeleteIntegrationTypeMutation}
            editIntegration={useEditIntegrationTypeMutation}
            isPartnerPortal={false}
            fetchApiKeys={useFetchApiKeyDetailsQuery}
            generateApiKeys={useGenerateApiKeyMutation}
            deleteApiKeys={useDeleteApiKeyMutation}
            portalAllowLink={portalAllowLink}
            isWL={isWL}
            track={track}
            EVENT_NAMES={EVENT_NAMES}
            accountInfo={accountInfo}
        />
    )
}
