import _ from 'lodash'
import React, {Component} from 'react'
import dumbBem from 'dumb-bem'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import tx from 'transform-props-with'
import queryString from 'query-string'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {fetchCredentials} from 'src/newcore/redux/modules/userManagement'
import {UsersPageTable} from './UsersTable'
import {INTEGRATION_TYPE, ACCESS_LEVEL} from 'ca-common/constants'
import {PAGE_SIZE} from 'ca-common/features/UserManagementTable/lib/constants'
import {EndUserRecovery} from './EndUserRecovery'

const dumbUsers = dumbBem('ca-users-page')
const CAUsers = tx(dumbUsers)('div')

class UsersPageRaw extends Component {
    componentDidMount() {
        const {actions, history} = this.props
        const params = queryString.parse(history.location.search)

        const pickedParams = _.pickBy({...params, page: 'page' in params ? +params.page : 1}, _.identity)

        actions
            .fetchCredentials({
                page: +params.page || 1,
                limit: PAGE_SIZE
            })
            .then(() =>
                history.push(`${history.location.pathname}?${queryString.stringify(pickedParams, {sort: false})}`)
            )
    }

    render = () => {
        const {actions, credentials, userInfo} = this.props
        const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX

        const isAdmin = userInfo?.permissions.accessLevel === ACCESS_LEVEL.FULL

        return credentials.status !== 'fulfilled' ? (
            <Spinner modifier="page" />
        ) : (
            <CAUsers>
                {isAdmin && <EndUserRecovery isZix={isZix} userInfo={userInfo} />}
                <UsersPageTable
                    credentials={credentials}
                    fetchCredentials={actions.fetchCredentials}
                    pageSize={PAGE_SIZE}
                    isZix={isZix}
                />
            </CAUsers>
        )
    }
}

const mapStateToProps = state => ({
    credentials: {
        status: state.credentials.status,
        ...state.credentials.response
    },
    userInfo: {
        status: state.userInfo.status,
        ...state.userInfo.response
    }
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                fetchCredentials
            },
            dispatch
        )
    }
})

export const UsersPage = connect(mapStateToProps, mapDispatchToProps)(UsersPageRaw)
