import styled from 'styled-components'

import variables from 'ca-common/variables'
import {BlockTitle} from 'ca-common/ui-lib/atoms/Typography'

export const StyledHeaderTaskInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 300px;
`
export const StyledJobLabel = styled.span`
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.navyBlue};
    margin-right: 26px;
    font-weight: 700;
    line-height: 18px;
`

export const StyledDescription = styled.span`
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.navyBlue};
    font-weight: 400;
    line-height: 18px;
`

export const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
`
export const IconRadiusWrapper = styled.div`
    background: ${variables.blueLight};
    padding: 18px;
    border-radius: 50%;
    margin-right: ${variables.gutterSm};
`
export const TaskName = styled(BlockTitle)`
    margin-bottom: 4px;
    color: ${variables.navyBlue};
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
export const SourceName = styled.span`
    margin-bottom: 4px;
    font-weight: 600;
    color: ${variables.navyBlue};
`
export const UserEmail = styled.div`
    span {
        display: block;
        color: ${variables.navyBlue};
        font-size: 12px;
        max-width: 600px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

export const StyledSection = styled.div`
    margin-top: 5%;
    margin-left: 1.5%;
    width: max-content;
`
