const variables = {
    // Colors
    dark: '#34474F',
    brand: '#262D61',
    boxShadow: '#00000014',
    offBoxShadow: '#20288029',
    brandDarkHover: '#172C47',
    text: '#000321',
    brandLight: '#EDEFF6',
    asphalt: '#738F9D',
    asphaltLight: '#B3BEC3',
    blue: '#DAE1E8',
    blueLight: '#F4F6F8',
    newBlueLight: '#F4F5F7',
    lightIceBlue: '#E6F9FF',
    iceBlue: '#4FC7FF',
    royalBlue: '#4F7DFF',
    white: '#FFFFFF',
    background: '#F1F3F3',
    green: '#6FCC33',
    red: '#FF0E0E',
    lightred: '#D30505',
    orange: '#F2994A',
    brightOrange: '#F38B0B',
    darkOrange: '#F76C26',
    lightorange: '#F2994A1A',
    black: '#000',
    brandBlack: '#333333',
    brandDark: '#041C3B',
    darkBlue: '#05152C',
    navyBlue: '#202880',
    navyBlueBorder: '#262B63',
    navyAsphalt: '#687F88',
    navyBorder: '#D9DFE1',
    darkNavy: '#121838',
    brightBlue: '#4688F1',
    lightGray: '#EDEDED',
    gray: '#BBBBBB',
    DarkGrey: '#687F88',
    mistyRose: '#FFE6E6',
    disabled: '#EDEDED',
    radioBlockBackground: '#F4F4F9',
    oktaColor: '#137fbf',
    midnightblue: '#232F3E',
    lightOrange: '#fef5ed',
    lightRed: '#ffe7e7',
    clearTagColor: '#B3BEC3',
    systemGreen: '#10C743',
    zixColor: '#334AFF',
    errorGradientStart: '#F9FAFB',
    darkBlueBackground: '#161D42',
    offwhite: '#DADFE1',
    offGrey: '#F4F4F7',
    lightgrey: '#E6E9EB',
    lightgreen: '#10c7431a',
    lightGreenNoOpacity: '#DBEFE2',
    tooltipBackground: '#4D5C73',
    signupBackground: '#f2f5f7',
    signupText: '#0a1527',
    reportHeaderColor: '#33464f',
    inProcess: '#5582FF',
    ligthBlack: '#3B3A39',
    greyLight: '#e7e7e7',
    pastelBlue: '#B2BEC5',
    defaultBorder: '#D9D9D9',
    linkBlue: '#0C6CBC',

    // Interactions
    interaction: '#E9EAF2',
    interaction5: '#F4F4F9',
    interaction20: '#D2D4E6',
    interaction110: '#1D2473',
    old: {
        green: '#03BF9C',
        red: '#ff3322',
        redLight: '#E95353',
        blueLight: '#262D61',
        grayDark: '#c1c9d1',
        gray: '#b0bbc6',
        duskyBlue: '#5163a8',
        hoverBlue: '#697bc2'
    },
    // Gutters
    gutterXs: '8px',
    gutterSm: '16px',
    gutterMd: '24px',
    gutterLg: '32px',
    gutterXxl: '40px',

    fontSizeBase: '14px',
    fontSizeSecondary: '12px',
    mainTitleFontSize: '24px',
    headingFontSize: '20px',
    sectionFontSize: '32px',
    subTitleFontSize: '16px',
    borderRadiusBase: '4px',
    btnBorderRadiusBase: '4px',
    borderRadiusBlocks: '8px',
    paymentIconSize: '35px',

    // Media
    screenSm: '576px',
    screenMd: '768px',
    screenLg: '992px',
    screenXl: '1200px',
    screenXxl: '1600px',

    notificationScreen: '940px',
    notificationMin: '555px',

    // Media Devices
    screenXsLaptop: '1332px',
    screenLaptop: '1366px',
    screenDesktop: '1440px',
    screenFullHD: '1920px',

    // Layout
    sidebarWidth: '88px',
    headerHeight: '64px',
    headerWideHeight: '170px',
    modalWidth: '630px',

    // Fonts
    publicPageFont: `'Lato', 'Helvetica', sans-serif`,
    mainFont: `'Open Sans', 'Helvetica', sans-serif`,
    accentFont: `'Quicksand', 'Verdana', sans-serif`,

    shadowBaseColor: '#0e1f35'
}

module.exports = variables
