import React from 'react'
import Icon from '@ant-design/icons'
import {useTranslation} from 'react-i18next'

import Copy from 'ca-common/icons/Copy.svg'
import CheckedGreen from 'ca-common/icons/CheckedGreen.svg'
import {openCopiedNotification} from 'ca-common/utils/toasts'

import {StyledIcon, StyledCopiedData, StyledCheckIcon, StyledText} from './StyledCopyToClipboard'

type CopyToClipboardProps = {
    textToCopy: string
}

export const CopyToClipboard = (props: CopyToClipboardProps) => {
    const {textToCopy} = props
    const {t} = useTranslation()
    const successIcon = <StyledCheckIcon component={CheckedGreen} />
    const successText = <StyledText>{t('modals:title:textCopied')}</StyledText>
    const closeIcon = <></>

    const copyText = () => (event: React.MouseEvent<HTMLImageElement>) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            openCopiedNotification(successText, successIcon, closeIcon)
        })
    }

    return (
        <>
            <StyledIcon component={Copy} onClick={copyText()} />
        </>
    )
}
