import styled, {css} from 'styled-components'
import {Button, Menu} from 'antd'
import Icon from '@ant-design/icons'
import React from 'react'
import {ButtonProps} from 'antd/es/button'

import variables from 'ca-common/variables'

type ActionIconProps = {
    disabled?: boolean
}

type BackupActionButtonProps = {
    isRecovery?: boolean
    disabled?: boolean
    greyedOut?: boolean
    isMsEndUser?: boolean
} & ButtonProps
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BackupActionButton = styled(
    ({isRecovery, disabled, greyedOut, isMsEndUser, ...props}: BackupActionButtonProps) => (
        <Button disabled={disabled} shape="round" {...props} />
    )
)`
    grid-area: button;
    font-family: ${props => (props.isMsEndUser ? variables.accentFont : variables.mainFont)};
    font-size: ${props => (props.isMsEndUser ? variables.fontSizeBase : variables.fontSizeSecondary)};
    font-weight: ${props => (props.isMsEndUser ? 700 : 400)};
    text-align: center;
    display: flex;
    height: 28px;
    line-height: 16px;
    width: ${props => (props.isMsEndUser ? '136px' : '')};
    align-items: center;
    justify-content: center;
    border: 1px solid ${variables.navyBlue};
    color: ${variables.navyBlue};
    padding-top: 0;
    padding-bottom: 0;
    .anticon {
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover,
    &:focus {
        background: ${variables.radioBlockBackground};
    }
    &:active {
        background: ${variables.interaction20};
    }
    ${props =>
        props.greyedOut &&
        css`
            && {
                color: ${variables.asphaltLight};
                border-color: ${variables.asphaltLight};
                background-color: ${variables.white};

                .anticon {
                    color: ${variables.asphaltLight};
                }
            }
        `}
    ${props =>
        props.isRecovery &&
        css`
            grid-area: recovery;
            background: ${variables.interaction20};
            border: none;
            &:hover,
            &:focus {
                background: ${variables.interaction110};
                color: ${variables.white};
                .anticon {
                    color: ${variables.white};
                    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
            }
            &:active {
                background: ${variables.navyBlue};
            }
        `}
    ${props =>
        props.disabled &&
        (props.isRecovery
            ? css`
                  && {
                      pointer-events: none;
                      color: ${variables.asphaltLight};
                      background-color: ${variables.lightGray};

                      .anticon {
                          color: ${variables.asphaltLight};
                      }
                  }
              `
            : css`
                  && {
                      pointer-events: none;
                      color: ${variables.asphaltLight};
                      border-color: ${variables.asphaltLight};
                      background-color: ${variables.white};

                      .anticon {
                          color: ${variables.asphaltLight};
                      }
                  }
              `)}
`

export const ActionIcon = styled(Icon)<ActionIconProps>`
    svg {
        fill: none;
    }
    font-size: 16px;
    width: 16px;
    height: 16px;
    color: ${variables.navyBlue};
    align-items: center;
    justify-content: center;
    display: flex;
`
export const MenuItem = styled(Menu.Item)``
export const DisabledButtonWrapper = styled.div<{disabled: boolean}>`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const ActionTitle = styled.div`
    margin: ${variables.gutterXs} 10px;
`
