import styled from 'styled-components'
import {Input, Select, TreeSelect, Form} from 'antd'

import variables from 'ca-common/variables'

export const VerticalFormItem = styled(Form.Item)`
    .ant-form-item-label,
    .ant-legacy-form-item-control-wrapper {
        width: 100%;

        label {
            display: block;
            width: 100%;
        }
    }

    .ant-form-item-control {
        min-width: auto;
    }
`

export const Label = styled.span`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: ${variables.dark};
`

export const RoundedThreeSelect = styled(TreeSelect)`
    .ant-select-selection,
    .ant-select-selector {
        border-radius: 20px !important;
        overflow: hidden;
    }
    .ant-select-selection-overflow {
        max-width: 95%;
    }
    width: 322px !important;
`

export const RoundedSelect = styled(Select)`
    .ant-select-selection {
        border-radius: 20px;
    }
`

export const RoundedInput = styled(Input)<any>`
    border-radius: 20px;
`
