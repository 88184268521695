import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    margin-left: 3px;
    cursor: pointer;
    svg {
        fill: none;
    }
`

export const StyledCopiedData = styled.div`
    width: 266px;
    height: ${variables.gutterLg};
    border: 1px solid ${variables.systemGreen};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.borderRadiusBase} ${variables.fontSizeXs} ${variables.fontSizeXs} 54px;
    background: ${variables.lightgreen};
    margin-left: 18%;
    margin-top: ${variables.subTitleFontSize};
`

export const StyledCheckIcon = styled(Icon)`
    margin-top: 9%;
    svg {
        height: ${variables.mainTitleFontSize};
        width: ${variables.mainTitleFontSize};
    }
`

export const StyledText = styled.span`
    margin-left: -18px;
    font-size: ${variables.fontSizeSecondary};
    font-weight: 400;
`
