import React from 'react'
import Icon from '@ant-design/icons'
import {Trans, useTranslation} from 'react-i18next'
import {DrawerProps} from 'antd/lib/drawer'

import DocIcon from 'ca-common/icons/help/Doc.svg'
import PlayIcon from 'ca-common/icons/help/Play.svg'
import KnowledgeIcon from 'ca-common/icons/help/Knowledge.svg'
import StarsIcon from 'ca-common/icons/help/Stars.svg'

import {
    GETTING_STARTED_BACKUP_ACTIVATION_URL,
    GETTING_STARTED_RESTORE_DATA_URL,
    PRODUCT_RELEASES_URL,
    SEE_ALL_URL,
    USER_GUIDES_URL,
    END_USER_GUIDES_URL
} from 'ca-common/features/BaseHeader/lib/constants'

import {
    StyledDrawer,
    StyledSubtitle,
    StyledReadUserGuides,
    StyledUserGuidesLink,
    StyledSeeAll,
    StyledButton
} from './StyledHelpDrawer'

type HelpDrawerProps = {
    isMsEndUser: boolean | undefined
    onClose: () => void
    open: boolean
}

export const HelpDrawer = (props: HelpDrawerProps) => {
    const {t} = useTranslation()
    const {isMsEndUser} = props

    return (
        <StyledDrawer title={t('help:title')} placement="right" width={315} getContainer={false} {...props}>
            <StyledSubtitle>{t('help:gettingStarted')}</StyledSubtitle>

            {isMsEndUser ? (
                <>
                    <StyledButton
                        shape="default"
                        size="middle"
                        type="text"
                        icon={<Icon component={PlayIcon} style={{fontSize: '20px'}} />}
                        href={GETTING_STARTED_RESTORE_DATA_URL}
                        target="_blank"
                    >
                        {t('help:video:howToRecoverData')}
                    </StyledButton>
                    <StyledReadUserGuides>
                        <Icon component={KnowledgeIcon} style={{fontSize: '24px'}} />
                        <span>
                            <Trans i18nKey="help:readOurUserGuides">
                                <StyledUserGuidesLink to={END_USER_GUIDES_URL} pureLink target="_blank" />
                            </Trans>
                        </span>
                    </StyledReadUserGuides>
                </>
            ) : (
                <>
                    <StyledButton
                        shape="default"
                        size="middle"
                        type="text"
                        icon={<Icon component={PlayIcon} style={{fontSize: '20px'}} />}
                        href={GETTING_STARTED_BACKUP_ACTIVATION_URL}
                        target="_blank"
                    >
                        {t('help:video:howToCreateBackupTask')}
                    </StyledButton>
                    <StyledButton
                        shape="default"
                        size="middle"
                        type="text"
                        icon={<Icon component={PlayIcon} style={{fontSize: '20px'}} />}
                        href={GETTING_STARTED_RESTORE_DATA_URL}
                        target="_blank"
                    >
                        {t('help:video:howToRecoverData')}
                    </StyledButton>
                    <StyledSeeAll to={SEE_ALL_URL} pureLink target="_blank">
                        {t('help:seeAll')}
                    </StyledSeeAll>
                    <StyledReadUserGuides>
                        <Icon component={KnowledgeIcon} style={{fontSize: '24px'}} />
                        <span>
                            <Trans i18nKey="help:readOurUserGuides">
                                <StyledUserGuidesLink to={USER_GUIDES_URL} pureLink target="_blank" />
                            </Trans>
                        </span>
                    </StyledReadUserGuides>
                    <StyledSubtitle>{t('help:whatsNew')}</StyledSubtitle>
                    <StyledButton
                        shape="default"
                        size="middle"
                        type="text"
                        icon={<Icon component={StarsIcon} style={{fontSize: '16px'}} />}
                        data-inlinemanual="InlineManual-latestReleaseUpdates"
                    >
                        {t('help:news:latestReleaseUpdates')}
                    </StyledButton>
                    <StyledButton
                        shape="default"
                        size="middle"
                        type="text"
                        icon={<Icon component={DocIcon} style={{fontSize: '16px'}} />}
                        href={PRODUCT_RELEASES_URL}
                        target="_blank"
                    >
                        {t('help:news:allReleaseNotes')}
                    </StyledButton>
                </>
            )}
        </StyledDrawer>
    )
}
