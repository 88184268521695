import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {ForecastCard} from 'src/newcore/components/Billing/Pages/Status/ForecastCard'

import {
    StyledSubscriptionBlock,
    StyledForecastCardWrapper,
    StyledSubscribe,
    StyledHeader
} from './StyledSubscribtionBlock'

type SubscriptionBlockProps = {
    usersBilling: Record<string, any>
    isNoBillsStatus?: boolean
    isSubscriptionLoading?: boolean
    onAnnualSubscription: () => void
    onMonthlySubscription: () => void
    showPaymentDetails?: boolean
    isUnavailableCurrency?: boolean
    isLoading?: boolean
    showCurrencySwitcher: boolean
    isPartnerCustomerAccount: boolean
}

export const SubscriptionBlock = ({
    usersBilling,
    isNoBillsStatus,
    onAnnualSubscription,
    onMonthlySubscription,
    isSubscriptionLoading,
    showPaymentDetails,
    isUnavailableCurrency,
    isLoading,
    showCurrencySwitcher,
    isPartnerCustomerAccount
}: SubscriptionBlockProps) => {
    const {t} = useTranslation()
    return (
        <StyledSubscriptionBlock>
            <StyledHeader>
                <StyledSubscribe>
                    {isUnavailableCurrency && !isPartnerCustomerAccount
                        ? t('billing:alert:portalSubscribeUnavailableCurrency')
                        : t('billing:alert:portalSubscribe')}
                </StyledSubscribe>
            </StyledHeader>
            <StyledForecastCardWrapper>
                {(!isUnavailableCurrency || isPartnerCustomerAccount) && (
                    <ForecastCard
                        data={usersBilling}
                        title={t(`forecastCard:monthlyTitle`)}
                        subtitle={t(`forecastCard:monthlySubtitle`)}
                        note={<Trans i18nKey="forecastCard:monthlyNote" tOptions={usersBilling} />}
                        subscriptionType="MONTHLY"
                        isNoBillsStatus={isNoBillsStatus}
                        onClick={onMonthlySubscription}
                        isLoading={isSubscriptionLoading}
                        showPaymentDetails={showPaymentDetails}
                        isLoadingData={isLoading}
                    />
                )}
                <ForecastCard
                    data={usersBilling}
                    title={t(`forecastCard:annualTitle`)}
                    subtitle={
                        <Trans
                            i18nKey={
                                isUnavailableCurrency
                                    ? t(`forecastCard:annualSubtitle`)
                                    : t(`forecastCard:annualSubtitleCC`)
                            }
                        />
                    }
                    subscriptionType="YEARLY"
                    isNoBillsStatus={isNoBillsStatus}
                    onClick={onAnnualSubscription}
                    isLoading={isSubscriptionLoading}
                    showPaymentDetails={showPaymentDetails}
                    isUnavailableCurrency={isUnavailableCurrency}
                    isLoadingData={isLoading}
                />
            </StyledForecastCardWrapper>
        </StyledSubscriptionBlock>
    )
}
