import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Switch, Badge, Tooltip} from 'antd'

import {ModalView} from 'ca-common/components/ModalView'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {CopyToClipboard} from 'ca-common/components/CopyToClipboard'
import {INTEGRATION_TYPE} from 'ca-common/constants'
import type {UserInfoDataType} from 'ca-common/types'

import {
    useGetEndUserValueQuery,
    useUpdateEndUserValueMutation
} from 'src/newcore/components/Settings/Pages/Users/EndUserRecovery/redux'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'
import {track} from 'src/newcore/utils/mixpanel'

import {secureLoginUrl} from './config'
import {StyledSection, StyledSelection, StyledDetails, StyledEndUser, StyledLink} from './StyledEndUserRecovery'

type EndUserRecoveryProps = {
    isZix: boolean
    userInfo: UserInfoDataType
}
export const EndUserRecovery = (props: EndUserRecoveryProps) => {
    const {isZix, userInfo} = props
    const {t} = useTranslation()
    const [isRecoverySelected, setIsRecoverySelected] = useState(false) // show/hide modal
    const {data: isEnabled, refetch} = useGetEndUserValueQuery('')
    const [updateEndUserValue, {error}] = useUpdateEndUserValueMutation()
    const modalWidth = 628
    const modalValue = {
        buttonTitle: t('modals:buttons:confirm'),
        title: isEnabled ? t(`settings:endUsers:disableTitle`) : t(`settings:endUsers:enableTitle`),
        description: isEnabled ? t(`settings:endUsers:disableDescription`) : t(`settings:endUsers:enableDescription`)
    }
    const partnerID = userInfo?.partnerID
    const loginURL = secureLoginUrl + partnerID

    const updateSelection = (value: boolean) => {
        setIsRecoverySelected(true)
    }

    const closeModal = () => {
        setIsRecoverySelected(false)
    }

    const onSubmit = () => {
        updateEndUserValue({enabled: !isEnabled})
            .then(async () => {
                await refetch()
                setIsRecoverySelected(false)
                const message = !isEnabled
                    ? t(`settings:endUsers:successEnableMessage`)
                    : t(`settings:endUsers:successDisableMessage`)
                openSuccessNotification(message)
            })
            .catch(err => {
                setIsRecoverySelected(false)
            })
    }

    return (
        <StyledSection>
            <ModalView
                modalVisible={isRecoverySelected}
                closeModal={closeModal}
                modalData={modalValue}
                modalWidth={modalWidth}
                onSubmit={onSubmit}
            />
            <StyledSelection>
                <Tooltip title={t(`settings:endUsers:betaTooltip`)} placement={'bottom'}>
                    <Badge count={t('oneClickActivation:button:beta')} title="" />
                </Tooltip>
                <StyledEndUser>{t(`settings:endUsers:title`)}</StyledEndUser>
                <Switch checked={isEnabled} onChange={updateSelection} />
            </StyledSelection>
            <StyledDetails>{t(`settings:endUsers:details`)}</StyledDetails>
            {isZix && (
                <StyledDetails>
                    {t(`settings:endUsers:secureUserLogin`)}
                    <StyledLink href={loginURL} target="_blank">
                        {loginURL}
                    </StyledLink>
                    <CopyToClipboard textToCopy={loginURL} />
                </StyledDetails>
            )}
        </StyledSection>
    )
}
