import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledSection = styled.div`
    background-color: ${variables.white};
    padding: ${variables.gutterSm};
`
export const StyledEndUser = styled.span`
    padding-left: ${variables.gutterXs};
`

export const StyledSelection = styled.div`
    margin-bottom: ${variables.gutterSm};
    font-family: ${variables.accentFont};
    font-size: ${variables.subTitleFontSize};
    font-weight: 500;
    color: ${variables.text};

    .ant-switch {
        margin-left: ${variables.gutterSm};
    }

    .ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
    .ant-badge-not-a-wrapper .ant-scroll-number {
        background-color: ${variables.inProcess};
        margin-top: -${variables.borderRadiusBase};
    }
`

export const StyledDetails = styled.div`
    font-size: ${variables.fontSizeBase};
    font-weight: 300;
`

export const StyledLink = styled.a`
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    color: ${variables.navyBlue};
    text-decoration: underline !important;
`
