import {i18n} from 'ca-common/locales'

import SplunkIcon from 'ca-common/images/Integrations/Splunk.svg'
import AutoTaskIcon from 'ca-common/images/Integrations/Autotask.svg'
import BackupRadarIcon from 'ca-common/images/Integrations/BackupRadar.svg'
export const BackupRadarLink = 'https://www.scalepad.com/backup-radar'

export const IntegrationSupportLink = ' https://support.cloudally.com/hc/en-us/requests/new'

export const AutoTaskLink = 'https://www.datto.com/products/autotask-psa'

export const SplunkSetupHelpLink =
    'https://docs.splunk.com/Documentation/SplunkCloud/latest/Data/UsetheHTTPEventCollector'

export const validEmail = new RegExp(
    /^(https?:\/\/)[a-zA-Z0-9\-\.]+:(?!0{1,5}\b)[0-9]{1,5}(\/[^\s]*)?$/ // format "https://<domain>:<port>"
)

export const splunkCheckBoxValues = ['SYSTEM_ACTIVITY', 'SECURITY_AUDIT', 'PP_AUDIT']

export const splunkFormInputs = ['endpoint', 'token', 'tag']

export const IntegrationServices = {
    AUTO_TASK: 'AUTOTASK',
    BACKUP_RADAR: 'BACKUP_RADAR_MAIL',
    SPLUNK: 'SPLUNK_HEC',
    API: 'API'
}

export const IntegrationNames = {
    AUTO_TASK: 'Autotask',
    BACKUP_RADAR: 'Backup Radar',
    SPLUNK: 'Splunk'
}

export const MIN_CHECKBOX = 1

export const IntegrationList = [
    {
        menuTitle: i18n.t('integrations:siemTitle'),
        index: '1',
        items: [
            {
                description: i18n.t('integrations:splunkDescription'),
                link: 'https://www.splunk.com',
                name: i18n.t('integrations:splunkName'),
                type: IntegrationServices.SPLUNK,
                isActive: false,
                isFailed: false,
                lastFailure: ''
            }
        ]
    },
    {
        menuTitle: i18n.t('integrations:rmmTitle'),
        index: '2',
        items: [
            {
                description: i18n.t('integrations:autoTaskDescription'),
                link: 'https://www.datto.com/products/autotask-psa',
                name: i18n.t('integrations:autoTaskName'),
                type: IntegrationServices.AUTO_TASK,
                isActive: false,
                isFailed: false,
                lastFailure: ''
            },
            {
                description: i18n.t('integrations:backupRadarDescription'),
                link: BackupRadarLink,
                name: i18n.t('integrations:backupRadarName'),
                type: IntegrationServices.BACKUP_RADAR,
                isActive: false,
                isFailed: false,
                lastFailure: ''
            }
        ]
    },
    {
        menuTitle: i18n.t('integrations:apiDetails:api'),
        index: '3'
    }
]

export const IntegrationPortalList = [
    {
        menuTitle: i18n.t('integrations:siemTitle'),
        index: '1',
        items: [
            {
                description: i18n.t('integrations:splunkDescription'),
                link: 'https://www.splunk.com',
                name: i18n.t('integrations:splunkName'),
                type: IntegrationServices.SPLUNK,
                isActive: false,
                isFailed: false,
                lastFailure: ''
            }
        ]
    },
    {
        menuTitle: i18n.t('integrations:rmmTitle'),
        index: '2',
        items: [
            {
                description: i18n.t('integrations:autoTaskDescription'),
                link: 'https://www.datto.com/products/autotask-psa',
                name: i18n.t('integrations:autoTaskName'),
                type: IntegrationServices.AUTO_TASK,
                isActive: false,
                isFailed: false,
                lastFailure: ''
            }
        ]
    },
    {
        menuTitle: i18n.t('integrations:apiDetails:api'),
        index: '3'
    }
]

export const BackupRadarInfo = {
    title: i18n.t('integrations:backupRadarModal:header'),
    width: 566,
    crossIcon: false,
    type: IntegrationServices.BACKUP_RADAR,
    name: i18n.t('integrations:backupRadarName'),
    id: IntegrationServices.BACKUP_RADAR
}

export const AutoTaskInfo = {
    title: i18n.t('integrations:autoTaskModal:header'),
    width: 566,
    crossIcon: false,
    name: i18n.t('integrations:autoTaskName'),
    type: IntegrationServices.AUTO_TASK
}

export const SplunkInfo = {
    title: i18n.t('integrations:splunkModal:header'),
    width: 484,
    crossIcon: true,
    name: i18n.t('integrations:splunkName'),
    type: IntegrationServices.SPLUNK
}

export const splunkFormInitialData = {
    id: 1,
    endpoint: '',
    token: '',
    source: '',
    scope: '',
    SYSTEM_ACTIVITY: true,
    SECURITY_AUDIT: true,
    PP_AUDIT: true
}

export const splunkFormCheckboxPartner = [
    {
        name: 'SYSTEM_ACTIVITY',
        label: i18n.t('integrations:splunkModal:activityEvents')
    },
    {
        name: 'SECURITY_AUDIT',
        label: i18n.t('integrations:splunkModal:auditLog')
    },
    {
        name: 'PP_AUDIT',
        label: i18n.t('integrations:splunkModal:partnerEvents')
    }
]

export const splunkFormCheckbox = [
    {
        name: 'SYSTEM_ACTIVITY',
        label: i18n.t('integrations:splunkModal:activityEvents')
    },
    {
        name: 'SECURITY_AUDIT',
        label: i18n.t('integrations:splunkModal:auditLog')
    }
]
